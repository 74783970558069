import * as React from 'react';
import LazyLoad from 'react-lazyload';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import * as Models from '../../core/models';

export interface IImageComponent {
    src: string;
    alt: string;
    width?: number;
    height?: number;
    classNames: string | { [key: string]: string } | string[];
}

export interface IImageStateComponent {

}

class ImageComponent extends React.Component<IImageComponent, IImageStateComponent> {
    render(): JSX.Element {
        return (
            <LazyLoad once height={this.props.height}>
                <img crossOrigin="anonymous" src={this.props.src} alt={this.props.alt} className={classnames(this.props.classNames)} width={this.props.width} height={this.props.height} />
            </LazyLoad>
        );
    }
}

const mapStateToProps = (state: Models.State.IState, ownProps: IImageComponent) => ({});
const mapDipsatchToProps = (dispatch, ownProps: IImageComponent) => ({});
const Image = withRouter(connect(mapStateToProps, mapDipsatchToProps)(ImageComponent));

Image.PropTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    classNames: PropTypes.any.isRequired
};

export default Image;
