import { styled, mediaMin } from '../../styles';

export const StyledBox = styled.div`
    align-self: stretch;
    margin-bottom: ${props => (props.theme.g * 2) + 'px'};
    transition: all .5s ease;
    ${mediaMin.sm`
        flex-grow: 0;
        flex-shrink: 0;
        width: 50%;
    `}

    ${mediaMin.slg`
        width:30%
    `}

    &.is-expanded {
        flex-basis: 100%;
    }
`;

export const StyledBoxInner = styled.div`
    position: relative;
    padding: ${props => (props.theme.g * 2) + 'px'};
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    transition: all .3s ease;
    &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        border: none;
        border-left: 4px solid ${props => props.theme.c3};
        border-top: 4px solid ${props => props.theme.c3};
        position: absolute;
        top: 0;
        left: 0;
    }
    &::after {
        display: block;
        content: "";
        width: 20px;
        height: 10px;
        border: none;
        border-bottom: 1px solid ${props => props.theme.c3};
        border-right: 1px solid ${props => props.theme.c3};
        position: absolute;
        bottom: 0;
        right: 0;
    }
`;

export const StyledBoxExpanded = styled.div`
    flex-basis: 100%;
    display: none;
    color: ${props => props.theme.c3};
    margin-bottom: 12px;
    font-size:13px;
    .is-expanded & {
        display: block;
    }
    ${mediaMin.sm`
        margin-bottom:0;
        flex-basis: 60%;
    `}
`;

export const StyledBoxContent = styled.div`
        flex-shrink:0;
        flex-grow:1;
        flex-basis:100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        &.-full-width {
            flex-basis: 100%;
        }
        .is-expanded & {
            padding-right: 12px;
        }

        ${mediaMin.sm`
            flex-shrink: .4;
            flex-grow: .4;
            flex-basis: 40%;
        `}
`;

export const StyledBoxHd = styled.header`
        flex: 0 0 100%;
        margin-bottom: $g*2;
`;

export const StyledBoxTitle = styled.h2`
    font-weight: 600;
    font-size: u(18);
`;

export const StyledBoxCounter = styled.span`
    font-size: 13px;
    font-weight: 600;
    margin-bottom: ${props => props.theme.g + 'px'};
    display: block;
    color: ${props => props.theme.c3};
`;

export const StyledBoxBd = styled.div`
flex: 0 0 100%;
`;

export const StyledBoxFt = styled.div`
    flex: 0 0 100%;
    align-self: flex-end;
    position:absolute;
    bottom:0;
    left: ${props => props.theme.g * 2 + 'px'};
    ${mediaMin.sm`
        position:static;
        margin-top: ${props => props.theme.g + 'px'}; 
    `}
`;

// .box {


//     &__bd {
//         flex: 0 0 100%;
//     }

//     &__list {
//         list-style-type: none;
//         list-style-image: none;
//         padding: 0;
//         margin: 0;
//     }
//     &__li {
//         font-size: u(13);
//         margin-bottom: $g/2;
//     }

//     &__ft {
        // flex: 0 0 100%;
        // align-self: flex-end;
        // margin-top: $g;
//     }
//     &__link {
//         font-size: u(13);
//         text-decoration: underline;
//         &:hover, &:active, &:focus {
//             color: rgba($c3, .7)
//         }
//     }
// }
