import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
/* https://redux-saga.js.org/ */
import { IAction } from '../models';
import * as actions from '../actions/langugage.actions';
import * as Services from '../../core/services';

export function* langugageEffects() {
    yield takeEvery(
        actions.SWITCH_LANGUGAGE,
        function* switchLangugage(action: IAction) {
            Services.LangService.current = action.lang;
        }
    );
}
