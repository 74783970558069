import * as React from 'react';
import classnames from 'classnames';

export interface IRow {
    children: any;
    isExtended?: boolean;
    classNames?: any;
}
const Row = ({ children, isExtended, classNames }: IRow): JSX.Element => (
    <div className={classnames('row', { '-ext-gutter': isExtended }, classNames)}>
        {children}
    </div>
);

export default Row;
