
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import * as Redux from 'redux';

import * as Utils from '../core/utils';
import * as Models from '../core/models';
import * as actions from './actions';
import * as Effects from './effects';

import * as fromLangugage from './reducers/langugage.reducer';
import * as fromNavigation from './reducers/navigation.reducer';
import * as fromTranslation from './reducers/translation.reducer';
import * as fromContactForm from './reducers/contact-form.reducer';
import * as fromLoader from './loader/loader.reducer';

const initialState: Models.State.IState = {
    loader: fromLoader.initialState,
    langugage: null,
    isNavigating: false,
    translation: fromTranslation.initialState,
    contactForm: fromContactForm.initialState,
};

/* Reducers */
// const mainReducerOLD = (state: Models.State.IState = initialState, action: Models.State.IAction): Models.State.IState => {
//     switch (action.type) {
//         default:
//             return state;
//     }
// };



const mainReducer: Redux.Reducer<Models.State.IState> = Redux.combineReducers({
    loader: fromLoader.loaderReducer,
    langugage: fromLangugage.languageReducer,
    isNavigating: fromNavigation.navigationReducer,
    translation: fromTranslation.translationReducer,
    contactForm: fromContactForm.contactFormReducer,
});

const rootReducer = (state: Models.State.IState, action: Models.State.IAction) => {
    if (action.type === actions.RESET) {
        state = JSON.parse(JSON.stringify(initialState));
    }

    return mainReducer(state, action);
};

const loggerMiddleware = createLogger();
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const forMiddleware: any[] = [sagaMiddleware, thunkMiddleware];
if (Utils.IS_DEV) {
    forMiddleware.push(loggerMiddleware);
}
export const store = Redux.createStore(
    rootReducer,
    Redux.applyMiddleware(...forMiddleware)
);

/* Run the saga */
sagaMiddleware.run(Effects.langugageEffects);
sagaMiddleware.run(Effects.navigationEffects);
sagaMiddleware.run(Effects.translationEffects);
sagaMiddleware.run(Effects.contactFormSagas);
