/// <reference path="../typings/index.d.ts" />
/* https://scotch.io/tutorials/how-to-make-your-existing-react-app-progressive-in-10-minutes */
'use strict';
// import './polyfills';
import './app/Root';

import registerServiceWorker from './registerServiceWorker';

registerServiceWorker();  // Runs register() as default function

// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&subset=latin-ext');

