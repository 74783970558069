import * as React from 'react';

const PageHeader = ({ children }): JSX.Element => (
    <section className="page-sec -type-head">
        <div className="wrapper">
            <div className="row">
                {children}
            </div>
        </div>
    </section>
);

export default PageHeader;
