import { IAction } from '../models';
import { IContactForm } from '../models/contact-form.interface';
import * as actions from '../actions/contact-form.actions';

export const initialState: IContactForm = {
    isSubmitting: false,
    hasSubmitSucceeded: false,
    hasSubmitFailed: false,
}

export function contactFormReducer(state: IContactForm = initialState, action: IAction): IContactForm {
    switch (action.type) {
        case actions.CONTACT_FORM_SUBMIT_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                hasSubmitSucceeded: false,
                hasSubmitFailed: false,
            };

        case actions.CONTACT_FORM_SUBMIT_SUCCESS_REQUEST:
            return {
                ...state,
                isSubmitting: false,
                hasSubmitSucceeded: true,
                hasSubmitFailed: false,
            };

        case actions.CONTACT_FORM_SUBMIT_ERROR_REQUEST:
            return {
                ...state,
                isSubmitting: false,
                hasSubmitSucceeded: false,
                hasSubmitFailed: true,
            };

        case actions.CONTACT_FORM_RESTORE_FLAGS:
            return {
                ...state,
                isSubmitting: false,
                hasSubmitSucceeded: false,
                hasSubmitFailed: false,
            };

        default:
            return state;
    }
}
