import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import * as State from '../../state';
import * as Models from '../../core/models';
import * as Services from '../../core/services';

import Config from '../../config';
import classnames from 'classnames';

import ViewsMain from '../Views/ViewsMain/ViewsMain';
import ViewsContent from '../Views/ViewsContent/ViewsContent';

export interface IPageComponent {
    beforeContent?: any;
    classNames: string | string[];
    children: any;
}

interface IPageStateComponent { }

class PageComponent extends React.PureComponent<IPageComponent, IPageStateComponent> {
    public state: IPageStateComponent = {};

    constructor(props: IPageComponent) {
        super(props);
    }

    componentDidMount(): void { }

    render(): JSX.Element {
        const BeforeContent = this.props.beforeContent ? this.props.beforeContent : () => null;

        return (
            <ViewsMain classNames={classnames(this.props.classNames)}>

                <BeforeContent />

                <ViewsContent>

                    <section className="page">

                        {this.props.children}

                    </section>

                </ViewsContent>
            </ViewsMain>
        );
    }
}

const mapStateToProps = (state: Models.State.IState, ownProps: IPageComponent) => ({});
const mapDipsatchToProps = (dispatch, ownProps: IPageComponent) => ({});
const Page = connect(mapStateToProps, mapDipsatchToProps)(PageComponent);

Page.propTypes = {
    beforeContent: PropTypes.any,
    classNames: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired
};

export default Page;
