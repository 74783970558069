import * as React from 'react';
import { connect } from 'react-redux';

import * as State from '../../state';
import * as Models from '../../core/models';

import LoaderComponent from './loader.component';

export interface ILoaderContainer {
    isVisible: boolean;
    animation: any;
}

class LoaderContainer extends React.PureComponent<ILoaderContainer, any> {

    render() {
        return (
            <React.Fragment>
                {!this.props.isVisible ? null : <LoaderComponent {...this.props} />}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({});
const mapStateToProps = (state: Models.State.IState, ownProps) => ({
    isVisible: state.loader.isVisible,
    animation: state.loader.animation,
});

const Loader = connect(mapStateToProps, mapDispatchToProps)(LoaderContainer);

export default Loader;
