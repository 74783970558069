import { animation } from './loader.interface';

export const LOADER_SET_ANIMATION = '[Loader] Set animation';
export const LoaderSetAnimation = (animation: animation = null) => ({
    type: LOADER_SET_ANIMATION,
    animation,
});

export const LOADER_SET_VISIBILITY = '[Loader] Set visibility';
export const LoaderSetVisibility = (isVisible: boolean) => ({
    type: LOADER_SET_VISIBILITY,
    isVisible,
});