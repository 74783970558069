import * as Models from '../../core/models';

export const CONTACT_FORM_SUBMIT_REQUEST = '[Contact form] Request submit';
export const ContactFormSubmitRequest = (formData: Models.IContactFormSubmit) => (
    {
        type: CONTACT_FORM_SUBMIT_REQUEST,
        formData,
    }
);

export const CONTACT_FORM_SUBMIT_SUCCESS_REQUEST = '[Contact form] Success request submit';
export const ContactFormSuccessSubmitRequest = () => ({
    type: CONTACT_FORM_SUBMIT_SUCCESS_REQUEST,
});

export const CONTACT_FORM_SUBMIT_ERROR_REQUEST = '[Contact form] Error request submit';
export const ContactFormSubmitErrorRequest = (ex?: any) => ({
    type: CONTACT_FORM_SUBMIT_ERROR_REQUEST,
    ex,
});

export const CONTACT_FORM_RESTORE_FLAGS = '[Contact form] Restore flags';
export const ContactFormRestoreFlags = () => ({
    type: CONTACT_FORM_RESTORE_FLAGS,
});

