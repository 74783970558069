export default class Config {
    public static readonly baseUrl: string = 'https://sbmsmartaccounting.co.uk';
    public static readonly isDev: boolean = ISDEV; /* Global set in webpack config */
    public static readonly navAnimationTimeout: number = 500;
    public static readonly defaultLang: string = 'en';
    public static readonly email: string = `info.sbmuk@gmail.com`;
    public static readonly phoneNo: string = `0779 00 11 981`;

    public static readonly companyName: string = `SBM Smart Accounting Limited`;
    public static readonly pageTitle: string = `SBM Smart Accounting Limited - Accounting and business services`;

    public static readonly translationValidationTime: number = 48 * 60 * 60 * 1000; /* 48housr */

    public static readonly api = {
        url: `/api`,
        key: APIKEY, /* Global set in webpack config */
    };

    public static get compactPhoneNo(): string {
        return Config.phoneNo.replace(/\s/g, '');
    }
}
