import { IAction } from '../models';

export const SET_LANGUGAGE = `[Langugage] Set langugage`;
export const SetLangugage = (lang: string) => ({
    type: SET_LANGUGAGE,
    lang,
});

export const SWITCH_LANGUGAGE = `[Langugage] Switch`;
export const SwitchLangugage = (langLocale: string) => ({
    type: SWITCH_LANGUGAGE,
    lang: langLocale
});
