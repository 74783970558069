
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Loader } from './components';

import * as State from './state';

const theme = {
    c1: '#202a5d',
    c2: '#c12126',
    c3: '#ffffff',
    c4: '#737373',
    c5: '#f1f1f1',
    g: 12,
}

interface IRootComponent {
    store: any;
}

class Root extends React.Component<IRootComponent, undefined> {
    public AppContainer;

    componentDidMount() {
        import('./appContainer')
            .then(appContainer => this.AppContainer = appContainer.default)
            .then(() => {
                setTimeout(() => {
                    this.props.store.dispatch(State.actions.LoaderSetAnimation('out'));
                    setTimeout(() => this.props.store.dispatch(State.actions.LoaderSetVisibility(false)), 500);
                }, 1000);

                return this.forceUpdate();
            });
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Provider store={State.store}>
                    <React.Fragment>
                        <Loader />
                        {
                            this.AppContainer ? (
                                <Router>
                                    <Route path="/" component={this.AppContainer} />
                                </Router>
                            ) : null
                        }

                    </React.Fragment>
                </Provider>
            </ThemeProvider>
        );
    }
}

setTimeout(() => {
    async function fonts(...urls: string[]) {
        // https://github.com/google/fonts/issues/358
        const head: HTMLHeadElement = document.querySelector('HEAD');
        const importFontStyle: string = `@import url('${urls}');`
        const styles: HTMLStyleElement = document.createElement('style');
        styles.setAttribute('type', 'text/css');
        try {
            const responses: string[] = await Promise.all(urls.map(url => fetch(url).then(r => r.text())));
            let allFonts: string = '';
            for (let i = 0, j = responses.length; i < j; i++) {
                allFonts += responses[i].replace(/{/g, '{\nfont-display: swap;');
            }

            styles.appendChild(document.createTextNode(allFonts));
            head.appendChild(styles);
            return allFonts;

        } catch (ex) {
            styles.appendChild(document.createTextNode(importFontStyle));
            head.appendChild(styles);
        }
    }
    fonts('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&subset=latin-ext');
    ReactDOM.render(<Root store={State.store} />, document.getElementById('app'));
}, 0);
