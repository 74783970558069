import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import * as Utils from '../../core/utils';
import * as Models from '../../core/models';
import * as Services from '../../core/services';

import {
    StyledBox,
    StyledBoxInner,
    StyledBoxExpanded,
    StyledBoxContent,
    StyledBoxHd,
    StyledBoxTitle,
    StyledBoxCounter,
    StyledBoxBd,
    StyledBoxFt,
} from './box.styles';

import './_box.scss';

export interface IBoxComponent {
    id: string;
    title: string;
    content?: any;
    contentExpanded?: string;
    isExpanded?: boolean;
    isCollapsed?: boolean;
    url?: string;
    onToggle?: (counter: string, isExpanded: boolean) => void;
    location?: Models.IRoutesLocation;
    match?: Models.IRoutesMatch;
    history?: Models.IRoutesHistory;
    translation?: any;
}

export interface IBoxStateComponent {
    content: () => void | string;
    contentExpanded: any;
    isExpanded: boolean;
    animate: string;
}

class BoxComponent extends React.Component<IBoxComponent, IBoxStateComponent> {
    private _translationStatus: any;

    public state = {
        isExpanded: false,
        animate: null,
        content: () => null,
        contentExpanded: () => null,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            content: this.props.content ? this.props.content : this.state.content,
            isExpanded: !!this.props.isExpanded,
        });

        this._translationStatus = this.props.translation;
    }

    componentDidUpdate() {
        if (this.props.translation !== this._translationStatus) {
            this._translationStatus = this.props.translation;
            this.forceUpdate();
        }
    }

    public toggleExpand(e: React.MouseEvent<HTMLAnchorElement>): void {
        e.preventDefault();
    }

    public handleBack(event: React.MouseEvent<HTMLAnchorElement>): void {
        event.preventDefault();
        this.props.history.goBack();
    }

    render(): JSX.Element {
        const Content: any = this.state.content;
        const isExpanded: boolean = this.props.match.params.id === this.props.id;
        return (
            <StyledBox className={classnames('col -ext-gutter', { 'is-expanded': isExpanded, 'is-collapsed': this.props.isCollapsed, 'is-restoring': this.state.animate === 'out' })}>
                <StyledBoxInner>
                    <StyledBoxContent className={classnames({ '-full-width': typeof Content === 'string' })}>
                        <StyledBoxHd>
                            <StyledBoxCounter>{this.props.id}.</StyledBoxCounter>
                            <StyledBoxTitle dangerouslySetInnerHTML={Utils.createMarkup(this.props.title)}></StyledBoxTitle>
                        </StyledBoxHd>
                        {
                            typeof Content === 'function' ? (<StyledBoxBd><Content /></StyledBoxBd>) :
                                (<StyledBoxBd dangerouslySetInnerHTML={Utils.createMarkup(Content)}></StyledBoxBd>)
                        }


                        {this.props.contentExpanded ? (
                            <StyledBoxFt className="__ft">
                                {isExpanded ? (
                                    <a href="#" className="box__link" onClick={this.handleBack.bind(this)}>{Services._t(`READ_LESS`)}</a>
                                ) : (
                                        <Link className="box__link" to={this.props.location.pathname + '/' + this.props.url}>{Services._t(`READ_MORE`)}</Link>
                                    )}
                            </StyledBoxFt>
                        ) : null}
                    </StyledBoxContent>

                    {this.props.contentExpanded ?
                        (<StyledBoxExpanded className={classnames('__expanded',
                            {
                                '-animate-out': this.state.animate === 'out',
                                '-animate-in': this.state.animate === 'in'
                            })}
                            dangerouslySetInnerHTML={Utils.createMarkup(this.props.contentExpanded)}></StyledBoxExpanded>)
                        : null}
                </StyledBoxInner>
            </StyledBox>
        );
    }
}

const mapStateToProps = (state: Models.State.IState, ownProps) => ({
    language: state.langugage,
    translation: state.translation.data
});
const mapDipsatchToProps = (dispatch, ownProps) => ({});
const Box = withRouter(connect(mapStateToProps, mapDipsatchToProps)(BoxComponent));

Box.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentExpanded: PropTypes.string,
    isExpanded: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    onToggle: PropTypes.func,
    url: PropTypes.string,
};

export default Box;
