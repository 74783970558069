import * as State from '../../state';
import * as Models from '../models';
import Config from '../../config';

class Translation {
    private _validDate: number = Config.translationValidationTime;

    constructor() { }

    public getTranslationForKey(key: string): string {
        const state: Models.State.IState = State.store.getState();

        if (!state.translation.data || !state.translation.data[key]) { return `{{${key}}}`; }

        return state.translation.data[key];
    }

    public translationRequest(translation: string): Promise<any> {
        const localTranslation: string = window.localStorage.getItem(`_${translation}`);
        const lastUpdate: string = window.localStorage.getItem(`_t`);

        if (localTranslation && lastUpdate) {
            if (+lastUpdate + this._validDate >= new Date().getTime()) {
                return Promise.resolve(JSON.parse(localTranslation));
            }
        }

        return fetch(`${Config.baseUrl}/assets/lang/translation.${translation}.json`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
        })
            .then(response => response.text())
            .then(textResponse => textResponse.replace(/(\s{2,}|\r|\n|\f|\v)/gim, ''))
            .then(cleanText => {

                window.localStorage.setItem(`_${translation}`, cleanText);
                window.localStorage.setItem(`_t`, `${new Date().getTime()}`);

                return JSON.parse(cleanText);
            })
            .catch(ex => ex);
    }
}

export const TranslationService = new Translation();
export const _t = TranslationService.getTranslationForKey.bind(TranslationService);
