import { IAction } from '../models';
import { ITranslation } from '../models/translations.interface';
import * as actions from '../actions/translation.actions';

export const initialState: ITranslation = {
    data: null,
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
}

export function translationReducer(state: ITranslation = initialState, action: IAction): ITranslation {
    switch (action.type) {
        case actions.TRANSLATION_REQUEST:
            return {
                ...state,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            };

        case actions.TRANSLATION_SUCCESS_REQUEST:
            return {
                ...state,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                data: action.translation
            };

        case actions.TRANSLATION_ERROR_REQUEST:
            return {
                ...state,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            };

        default:
            return state;
    }
}
