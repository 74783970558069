import * as React from 'react';
import * as Utils from '../../core/utils';

import classnames from 'classnames';

interface IText {
    children: any;
    classNames?: any;
}
const Text = ({ children, classNames }: IText): JSX.Element => (
    <div className={classnames('text', classNames)}>
        <div className="text__inner" dangerouslySetInnerHTML={Utils.createMarkup(children)}></div>
    </div>
);

export default Text;
