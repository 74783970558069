import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
/* https://redux-saga.js.org/ */
/* https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html */
import { IAction } from '../models';
import * as actions from '../actions/contact-form.actions';
import * as Services from '../../core/services';

function* sendForm(action) {
    try {
        const response = yield call(Services.ContactService.sendMessage.bind(Services.ContactService), action.formData);

        if (response === 0) {
            throw new Error('Unable to send form');
        }

        yield put(actions.ContactFormSuccessSubmitRequest());
    } catch (ex) {
        yield put(actions.ContactFormSubmitErrorRequest(ex));
    }
}


export function* contactFormSagas() {
    yield takeEvery(actions.CONTACT_FORM_SUBMIT_REQUEST, sendForm);
}
