import Config from '../../config';

export interface IMetaOptions {
    title?: string;
    keywords?: string;
    description?: string;
}

class MetaServiceClass {
    private _REGEX_TRANSLATION_PATTERN: RegExp = /{{[^}]*}}/gi;

    public readonly defaults: IMetaOptions = {
        title: Config.pageTitle,
        keywords: `accounting, business services, bookkeeping, united kingdom, uk`,
        description: `We provide a full range of cost effective accounting services to UK businesses and individuals.`
    };

    public setMetaTags(opts: IMetaOptions = this.defaults): void {
        const newOptions = {
            ...this.defaults,
            ...opts,
        };

        let titleTag: HTMLTitleElement = document.querySelector(`title`) as HTMLTitleElement;
        let descriptionTag: HTMLMetaElement = document.querySelector(`meta[name="description"]`);
        let keywordsTag: HTMLMetaElement = document.querySelector(`meta[name="keywords"]`);

        if (!titleTag) {
            titleTag = document.createElement(`title`);
            document.head.append(titleTag);
        }
        const matchTitle: RegExpMatchArray = newOptions.title.match(this._REGEX_TRANSLATION_PATTERN);
        titleTag.innerHTML = matchTitle !== null ? Config.pageTitle : newOptions.title + ' - ' + Config.pageTitle;

        if (!descriptionTag) {
            descriptionTag = document.createElement(`meta`);
            descriptionTag.setAttribute(`name`, `description`);
            document.head.append(descriptionTag);
        }
        const matchDescTag: RegExpMatchArray = newOptions.description.match(this._REGEX_TRANSLATION_PATTERN);
        descriptionTag.setAttribute(`content`, matchDescTag !== null ? this.defaults.description : newOptions.description);

        if (!keywordsTag) {
            keywordsTag = document.createElement(`meta`);
            keywordsTag.setAttribute(`name`, `keywords`);
            document.head.append(keywordsTag);
        }

        const matchKeywordsTag: RegExpMatchArray = newOptions.keywords.match(this._REGEX_TRANSLATION_PATTERN);
        keywordsTag.setAttribute(`content`, matchKeywordsTag !== null ? this.defaults.keywords : newOptions.keywords);
    }
}

export const MetaService: MetaServiceClass = new MetaServiceClass();
