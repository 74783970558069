import { createGlobalStyle, styled, keyframes } from '../../styles';

const fadeOut = keyframes`
    0% {opacity:1}
    100% {opacity:0}
`;

export const StyledLoaderWrapper = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:13;
    background-color: ${props => props.theme.c3};
    &.-animate-out {
        animation: ${fadeOut} .5s ease;
    }
`;

export const StyledLoaderBody = styled.div`
    margin: 0px auto;
    width: 40px;
    height: 40px;
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
`;

const fadeDealyAnimation = keyframes`
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
`;
export const StyledLoaderCircle = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &::before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: ${props => props.theme.c1};
        border-radius: 100%;
        animation: ${fadeDealyAnimation} 1.2s infinite ease-in-out both;
    }

    &.-no-2 {
        transform: rotate(30deg);
        &::before {animation-delay: -1.1s;}
    }
    &.-no-3 {
        transform: rotate(60deg);
        &::before {animation-delay: -1s;}
    }
    &.-no-4 {
        transform: rotate(90deg);
        &::before {animation-delay: -0.9s;}
    }
    &.-no-5 {
        transform: rotate(120deg);
        &::before {animation-delay: -0.8s;}
    }
    &.-no-6 {
        transform: rotate(150deg);
        &::before {animation-delay: -0.7s;}
    }
    &.-no-7 {
        transform: rotate(180deg);
        &::before {animation-delay: -0.6s;}
    }
    &.-no-8 {
        transform: rotate(210deg);
        &::before {animation-delay: -0.5s;}
    }
    &.-no-9 {
        transform: rotate(240deg);
        &::before {animation-delay: -0.4s;}
    }
    &.-no-10 {
        transform: rotate(270deg);
        &::before {animation-delay: -0.3s;}
    }
    &.-no-11 {
        transform: rotate(300deg);
        &::before {animation-delay: -0.2s;}
    }
    &.-no-12 {
        transform: rotate(330deg);
        &::before {animation-delay: -0.1s;}
    }
`;
