import { IAction } from '../models';

export const TRANSLATION_REQUEST = `[Translation] Request`;
export const GetTranslationRequest = (lang: string) => ({
    type: TRANSLATION_REQUEST,
    lang,
});

export const TRANSLATION_SUCCESS_REQUEST = `[Translation] Success request`;
export const GetTranslationSuccessRequest = (translation: { [key: string]: string }) => ({
    type: TRANSLATION_SUCCESS_REQUEST,
    translation,
});

export const TRANSLATION_ERROR_REQUEST = `[Translation] Error request`;
export const GetTranslationErrorRequest = () => ({
    type: TRANSLATION_ERROR_REQUEST,
});