import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import * as Models from '../../core/models';

class NavDownComponent extends React.Component<undefined, undefined> {
    render(): JSX.Element {
        return (
            <div className="nav-down">
                <img src="/assets/images/icon.nav-down.png" alt="navigate down" className="nav-down__img" width="22" height="26" />
            </div>
        );
    }
}

const mapStateToProps = (state: Models.State.IState, ownProps) => ({});
const mapDipsatchToProps = (dispatch, ownProps) => ({});
const NavDown = withRouter(connect(mapStateToProps, mapDipsatchToProps)(NavDownComponent));

export default NavDown;
