import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
/* https://redux-saga.js.org/ */
/* https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html */
import { IAction } from '../models';
import * as actions from '../actions/translation.actions';
import * as Services from '../../core/services';

function* fetchTranslation(action) {
    try {
        const data = yield call(Services.TranslationService.translationRequest.bind(Services.TranslationService), action.lang);
        yield put(actions.GetTranslationSuccessRequest(data));
    } catch (ex) {
        console.error('got error', ex);
        yield put(actions.GetTranslationErrorRequest());
    }
}


export function* translationEffects() {
    yield takeEvery(actions.TRANSLATION_REQUEST, fetchTranslation);
}
