import { IAction } from '../models';
import * as actions from '../actions/navigation.actions';

export function navigationReducer(state: boolean = false, action: IAction): boolean {
    switch (action.type) {
        case actions.SET_NAVIGATE_FLAG:
            return action.flag;

        default:
            return state;
    }
}
