import * as styledComponents from 'styled-components';
/* https://github.com/patrick91/Styled-Components-Typescript-Example/blob/master/src/theme/index.ts */
const {
    default: styled,
    css,
    createGlobalStyle,
    keyframes,
    ThemeProvider
} = styledComponents;

export { styled, css, createGlobalStyle, keyframes, ThemeProvider };
