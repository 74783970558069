import * as React from 'react';
import * as Utils from '../../core/utils';

import Image from '../Image/Image';

import classnames from 'classnames';

export interface IMainHeaderImage {
    src: string;
    alt?: string;
    width?: number | string;
    height?: number | string;
}

export interface IMainHeader {
    children: string;
    image?: IMainHeaderImage;
    isDark?: boolean;
}
const MainHeader = ({ image, children, isDark }: IMainHeader): JSX.Element => {
    return (
        <header className={classnames('main-h col', { '-img': image })} >
            {image === undefined ? null : (<Image src={image.src} alt={image.alt} width={image.width} height={image.height} classNames="main-h__img" />)}
            {children === 'null' ? null : <h1 className={classnames('main-h__h', { '-dark': isDark })} dangerouslySetInnerHTML={Utils.createMarkup(children)}></h1>}

        </header>
    );
};

export default MainHeader;
