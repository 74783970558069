// // Breakpoints
// // -------------------------
// $xlg-up: 1920px;
// $xlg: 1919px;
// $lg-up: 1470px;
// $lg: 1469px;
// $slg-up: 1120px;
// $slg: 1119px;
// $md-up: 1040px;
// $md: 1039px;
// $sm-up: 787px;
// $sm: 786px;
// $xsm-up: 610px;
// $xsm: 609px;
// $xs-up: 480px;
// $xs: 479px;
// $xxs-up: 380px;
// $xxs: 379px;

// $xlg-up:bp($xlg-up, up); // min-width
// $xlg:bp($xlg); // max-width
// $lg-up:bp($screen-lg-desktop, up);
// $lg:bp($screen-md-max);
// $slg-up:bp($slg-up, up);
// $slg:bp($slg);
// $md-up:bp($screen-md-min, up);
// $md: bp($screen-sm-max);
// $sm-up:bp($screen-sm, up); 
// $sm:bp($screen-xs-max);
// $xsm-up:bp($xsm-up, up); 
// $xsm:bp($xsm);
// $xs-up:  bp($screen-xs, up);
// $xs: bp($screen-xs - 1);
// $xxs-up:bp($xxs-up, up); 
// $xxs:bp($xxs);
import { css } from './styled.components';

const sizesUp = { /* Min width */
    xlg: 1920,
    lg: 1470,
    slg: 1120,
    md: 1040,
    sm: 768,
    xsm: 610,
    xs: 480,
    xxs: 380,
};

const sizesDown = {}; /* Max width */

for (let prop in sizesUp) {
    sizesDown[prop] = sizesUp[prop] - 1;
}

export const mediaMin: { [key: string]: (...args: any) => any } = Object.keys(sizesUp).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${sizesUp[label] / 16}em) {
        ${css(...args)}
      }
    `;
    return acc;
}, {});

export const mediaMax: { [key: string]: (...args: any) => any } = Object.keys(sizesDown).reduce((acc, label) => {
    acc[label] = (...args) => css`@media (max-width: ${sizesDown[label] / 16}em) {
        ${css(...args)}
    }`;

    return acc;
}, {});
