import * as React from 'react';
import classnames from 'classnames';

interface IPageContent {
    children: any;
    isExtended?: boolean;
}

const PageContent = ({ children, isExtended }: IPageContent): JSX.Element => (
    <section className="page-sec -type-content">
        <div className={classnames('wrapper', {'-ext-gutter': isExtended})}>{children}</div>
    </section>
);

export default PageContent;
