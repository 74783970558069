import * as Models from '../models';
import Config from '../../config';

class Contact {
    public async sendMessage(formData: Models.IContactFormSubmit): Promise<boolean> {
        try {
            const request = await fetch(`${Config.baseUrl}/php/sent.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(formData)
            });
            const response = await request.json();

            console.warn(response);

            return response;
        } catch (ex) {
            console.error('ContactService', ex)
            throw ex;
        }
    }
}

export const ContactService: Contact = new Contact();
