import * as React from 'react';
import { StyledLoaderBody, StyledLoaderWrapper, StyledLoaderCircle } from './loader.styles';

export const LoaderComponent = ({ isVisible, animation }): JSX.Element => {
    let count: undefined[] = Array.from(Array(12));
    return (
        <React.Fragment>
            {!isVisible ? null : (
                <StyledLoaderWrapper className={`-animate-${animation}`}>
                    <StyledLoaderBody>
                        {count.map((item, index) => <StyledLoaderCircle className={`-no-${index + 1}`} key={index}></StyledLoaderCircle>)}
                    </StyledLoaderBody>
                </StyledLoaderWrapper>
            )}
        </React.Fragment>
    );
}

export default LoaderComponent;
