import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
/* https://redux-saga.js.org/ */
import { IAction } from '../models';
import * as actions from '../actions/navigation.actions';
import * as Services from '../../core/services';
import classnames from 'classnames';

export function* navigationEffects() {
    yield takeEvery(
        actions.SET_NAVIGATE_FLAG,
        function* setNavigation(action: IAction) {
            document.body.className = action.flag ? 'is-navigating' : '';
        }
    );
}
