import * as React from 'react';
import {withRouter} from 'react-router-dom';
const ViewsContent = ({ children }): JSX.Element => (
    <div className="view__inner">
        <div className="view__content">
            {children}
        </div>
    </div>
);

export default withRouter(ViewsContent);
