import { ILoader } from './loader.interface';
import * as actions from './loader.actions';

export const initialState: ILoader = {
    animation: null,
    isVisible: true,
}

export const loaderReducer = (state: ILoader = initialState, action) => {
    switch (action.type) {
        case actions.LOADER_SET_ANIMATION:
            return {
                ...state,
                animation: action.animation,
            };

        case actions.LOADER_SET_VISIBILITY:
            return {
                ...state,
                isVisible: action.isVisible,
            }

        default:
            return state;
    }
}