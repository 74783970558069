import { IAction } from '../models';
import * as actions from '../actions/langugage.actions';

export function languageReducer(state: string = null, action: IAction): string {
    switch (action.type) {
        case actions.SET_LANGUGAGE:
            return action.lang;

        default:
            return state;
    }
}
