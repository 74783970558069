import * as React from 'react';

import Page, { IPageComponent } from './Page';

interface IPageContainer {

}

const PageContainer = (props: IPageComponent): JSX.Element => {
    return <Page {...props} />;
};

export default PageContainer;
