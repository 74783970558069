import * as React from 'react';
import classnames from 'classnames';
// ForView
export interface IViewsMain {
    children: any;
    classNames?: string | { [key: string]: string } | string[];
}
const ViewsMain = ({ children, classNames }: IViewsMain): JSX.Element => (
    /* view -has-vertical-stripes -bg-color-dark -bg-content-video is-animating -out -forward */
    <div className={classnames('view -has-vertical-stripes', classNames)}>{children}</div>
);

export default ViewsMain;