import * as Models from '../models';
import * as Enums from '../enums';
import Config from '../../config';

export class RouterService {
    private _ROOT_PATH: RegExp = /^((\/|\\)?[^(\\|\/)]{1,}(\/|\\)?|(\s{0}|\\|\/))$/gi;
    private _PATH: RegExp = /(\\|\/)?[^(\\|\/)]{1,}/gi;
    private _navTimeout: number = Config.navAnimationTimeout + 100;
    private _isAnimating: boolean = false;
    private _navDirection: number;


    private _setNavDirection(direction: number): number {
        this._navDirection = direction;
        this._isAnimating = true;
        setTimeout(() => { this._navDirection = undefined; this._isAnimating = false; }, this._navTimeout);
        return direction;
    }

    public detectCanAnimate(currentPath: string, nextPath: string): boolean {
        if (this._isAnimating) { return true; }

        const splitPrev = currentPath.split('/');
        const splitCurr = nextPath.split('/');

        const isCommingBack = splitPrev.length > 2 && splitPrev[1].length !== 0 && splitCurr[1].length !== 0 && splitPrev[1] === splitCurr[1];
        const isEnteringNestedRoute = splitCurr.length > 2;
        const isNavigatingInNestedRoutes = isCommingBack || isEnteringNestedRoute;

        if (isNavigatingInNestedRoutes) {
            return false;
        }
        return true;
    }

    public detectNavDirection(currentPath: string, nextPath: string, routes: Models.IRouteObj[]): number {
        if (this._isAnimating && this._navDirection !== undefined) { return this._navDirection; }

        const currentRoute = routes.find(route => route.path === currentPath);
        const nextRoute = routes.find(route => route.path === nextPath);

        if (currentRoute && nextRoute === undefined) {
            return this._setNavDirection(1);
        }

        if (currentRoute === undefined && nextRoute) {
            return this._setNavDirection(0);
        }

        if (nextRoute === undefined && currentRoute === undefined) {
            return this._setNavDirection(null);
        }

        return nextRoute.id > currentRoute.id ? this._setNavDirection(1) : this._setNavDirection(0);

    }
}